import "./headers.css"
import logo from "../../assets/logo_white.png"
import {ReactComponent as Icon} from "../../assets/menu.svg"
import { useDispatch } from "react-redux"

export function CustomHeader(props){
    const dispatch = useDispatch()
    
    
    return (
        <header className="App-header">
            <div id="header-search">
                <Icon fill="#FFF" width={15} height={15} id="search-icon" onClick={()=>{
                    dispatch({
                        type: 'context/toggleSideMenu',
                        payload: true
                     })
                }}/>
            </div>

            <div id="header-logo">
                <img src={logo} alt="Logo" />
            </div>
            
            <div id = 'header-content'>
                <a href="/">Overview</a>
                <a href="/monitor">Monitor</a>
                <a href="http://20.55.80.49/tables" target="_blank" rel="noreferrer">Tables</a>
                <a href="https://20.55.80.49:8889/mui/" target="_blank" rel="noreferrer">Plots</a>
            </div>

            
        </header>
    )
}