import { useSelector } from "react-redux";
import { CustomHeader } from "../../components/CustomHeader";
import { SideMenu } from "../../components/SideMenu";
import { VariableSelector, ElementTypeSelector, GroupSelector } from "../../components/SideMenu/Selector";
import ShowData from "./ShowData";
import './main-content.css'

export function Monitor(){
    const {active, sideMenu} = useSelector((state) => state.context)
    const {elementType} = active

    const showMenu = (state) => {
        if (state){
            if(elementType === 'Inverter'){
                return <SideMenu><VariableSelector></VariableSelector></SideMenu>
            // }else if(elementType === 'Tracker'){
            //     return <SideMenu><GroupSelector></GroupSelector></SideMenu>
            }else{
                return <SideMenu><ElementTypeSelector></ElementTypeSelector></SideMenu>
            }
            
        }
    }

    const style = (state)=>{
        if (state){
            return {
                width: 'calc(100vw - 180px)'
            }
        }else{
            return {}
        }

    }

    return <>
        <CustomHeader></CustomHeader>
        
        <div id="monitor">
            {showMenu(sideMenu)}
            <ShowData style={style(sideMenu)} context={active}></ShowData>
        </div>
    </>
}