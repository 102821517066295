import { useCallback, useMemo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getTopicName } from "../../utils";
import LineChar from "../Chart";
import "./graphics.css"

function useSamples(){
    const [samples, setSamples] = useState({})

    const addSample = useCallback((sample, element, variables) =>{
        if (!(element)|!(sample)){
            return
        }
        const new_samples = samples
        const names = variables

        if (!(element in new_samples)){
            new_samples[element] = {}
        }
        console.log(sample)
        names.forEach((name) => {
                if (!(name in new_samples[element])){
                    new_samples[element][name] = {ts:[], val:[]}
                }
                if (sample){
                    new_samples[element][name].ts.push(sample.ts? sample.ts:0) 
                    new_samples[element][name].val.push(sample[name]? sample[name]:0) 
                }
            }
        )
        
        setSamples(new_samples)

    }, [samples])

    

    return [samples, addSample]

}

export function Graphics(props){
    const {plantName, elementType, elementName, variables} = props.config

    const key = getTopicName(plantName, elementType)
    const rawData = useSelector(state => state.mqtt[key])
    const data = useMemo(()=>{return rawData? rawData:{}}, [rawData])

    const [samples, addSample] = useSamples()

    const element = elementName

    
    
    useEffect(()=>{
        // console.log(data[element])
        addSample(data[element], element, variables)
    }, [element, variables, data, addSample])
    console.log(samples[element])

    return (<LineChar style={props.style} samples={samples? samples[element]:undefined}></LineChar>)
}

