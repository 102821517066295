import {createSlice} from '@reduxjs/toolkit'
import { ReactComponent as NaranjalHV } from "./assets/Naranjal-HV.svg"
import { ReactComponent as QuilapilunHV } from "./assets/Quilapilun-HV.svg"


const plantsNames = [
    'Jacaranda',"CasaBlanca 1", "CasaBlanca 2",'La Pimienta', 'Sol del Desierto', 'Bess del Desierto',
    'Javiera', "Quilapilun", 'Litoral', 'Naranjal','Guajiro', 
    "Boa Sorte",'Berrini','Atlas', "Patio Foster", "Andres Bello", "Vista Alegre"
]
const elementsTypes = ['Inverter', 'Meter', "WS", "PPC", "Its", "HV",'Tracker Master','Tracker', "Network", "CCTV", 'Infra',"Drone"]

const mappingElementTypeVariables = {
    Meter: {
        default:["Vll_ab", "Vll_bc", "Vll_ca", "Ia", "Ib", "Ic", "P", "Q"]
    },
    Inverter: {
        default: ["P", "Q", "P_set","Q_set"],
        Juazeiro: ["L1_Q4_temp", "L2_Q4_temp", "L3_Q4_temp"],
        "Sao Pedro": ["L1_Q4_temp", "L2_Q4_temp", "L3_Q4_temp"],
        Jacaranda: ["HSA_temp", "HSB_temp","HSC_temp", "P_set_reason"],
        Barreiras: ["HSA_temp", "HSB_temp","HSC_temp" ],
        "CasaBlanca 1": ["HSA_temp", "HSB_temp","HSC_temp" ],
        "CasaBlanca 2": ["HSA_temp", "HSB_temp","HSC_temp" ]
    },
    WS:{ default:['hum1','ambT1','ghi1','ghi2','wind1','panT','g1']},
    HV:{ default:[]}
 }
 
const maxISB = {
    default: 160,
    "La Pimienta": 200,
    "CasaBlanca 1": 200
};

const isbsExclusionList = {
    "La Pimienta": {
        default: ['I_SB9','I_SB10','I_SB11','I_SB12','I_SB13'
        ,'I_SB14','I_SB15','I_SB16','I_SB17','I_SB27'
        ,'I_SB28','I_SB29','I_SB30','I_SB31','I_SB32']
    },
    "CasaBlanca 2": {
        default: [
        //     'I_SB8','I_SB9','I_SB10','I_SB11','I_SB12','I_SB13'
        // ,'I_SB14','I_SB15','I_SB16','I_SB17','I_SB25','I_SB26','I_SB27'
        // ,'I_SB28','I_SB29','I_SB30','I_SB31','I_SB32'
    ]
    },

    "Sol del Desierto": {
        default: ['I_SB1','I_SB2','I_SB3','I_SB4','I_SB5'
        ,'I_SB6','I_SB23','I_SB24','I_SB25','I_SB26','I_SB27'
        ,'I_SB28','I_SB29','I_SB30','I_SB31','I_SB32']
    }
}

const faultMap = {
    'GE LV5 UL1': {
        'AlarmState1': 
            {
                0: 'Ok', 1: 'Heatsink overtemperature', 2: 'Earth current', 4: 'Line undervoltage', 8: 'Cooling liquid overtemperature',
                16: 'Cooling liquid low pressure', 32: 'Cooling liquid pressure sensor', 64: 'Cooling liquid temperature', 128: 'Discharge resistor',
                256: 'Cubicle overheating', 512: 'Power electronics overheating', 1024: 'Line coil overheating', 4096: 'Breaker commuting cycle', 8192: 'Earth fault',
                16384: 'Contactor to earth', 32767: 'Transformer overtemperature'
            },
        'AlarmState2': 
            {
                0: 'Ok', 1: 'CPU overtemperature', 2: 'CPU core overtemperature', 4: 'DC Link unbalance', 8: 'Fan lifetime expired',
                16: 'DC link voltage oscilation', 32: 'Cooling liquid source pressure sensor', 64: 'External temperature sensor', 128: 'Line coil temperature sensor',
                256: 'Power electronics temperature sensor', 512: 'Local/remote change', 1024: 'PV generator disconnected', 2048: 'MPPT Power measurement incorrect',
                4096: 'External fan', 8192: 'Field communication bus', 16384: 'AC Breaker switch cycle exceeded', 32767: 'DC Breaker switch cycle exceeded'
            },
        'AlarmState3': 
            {
                0: 'Ok', 1: 'Isolation warning', 2: 'Controller floating warning', 4: 'Overvoltage protection', 32: 'Fan filter', 
                128: 'Cubicle temperature warning', 256: 'SRAM failure', 512: 'Fieldbus communication warning', 1024: 'MV transformer overtemperature',
                2048: 'Isolation', 4096: 'CPU synchronization',
            },
        'FaultState1':
            {
                0: 'Ok', 1: 'ECAT operation fault', 2: 'FPGA version wrong', 4: 'PIB watchdog failure',
                8: 'DC link overvoltage', 16: 'Controller floating fault', 32: 'DC link short circuit',
                64: 'Fault to earth',  256: 'IGBT desaturation fault', 1024: 'IGBT feedback fault',
                8192: 'Interface board power fault',
            },
        'FaultState2': 
            {
                0: 'Ok', 1: 'PIB internal power source', 2: 'PIB measurement connector', 8: 'PIB amplifier connector', 16: 'PIB power source terminal',
                32: 'Current clipping timeout',  64: 'Short circuit', 256: 'Heatsink overtemperature', 512: '24V terminal short circuit',
                1024: 'Fieldbus connection fault', 2048: 'Battery synchronization fault', 4096: 'Short circuit to earth',  8192: 'DC link undervoltage'
            },
        'FaultState3': 
            {
                0: 'Ok', 1: 'Line voltage fault', 2: 'Line voltage measurent fault', 4: 'AC breaker activated', 8: 'AC breaker feedback',
                16: 'Precharge failure', 32: 'Line filter', 64: 'Discharge contactor feedback', 128: 'DC breaker feedback',
                256: 'Amplifier board contactor', 512: 'Current direction fault', 1024: 'UPS failure', 2048: 'PV circuit short circuit',
                4096: 'Isolation fault', 8192: 'Maximum output frequency', 16384: 'FPGA firing', 32767: 'PIB hardware config'
            },
        'FaultState4': 
            {
                0: 'Ok', 1: 'Heatsink temperature measurement', 2: 'Heatsink undertemperature', 4: 'External fan',
                8: 'Power electronics fan', 16: 'Line coil fan', 32: 'Cooling liquid overtemperature', 64: 'Cooling liquid underpressure',
                128: 'Cooling liquid pump failure', 256: 'Cooling liquid failure', 512: 'Power electronics overtemperature',
                1024: 'Line coil overtemperature', 2048: 'MV transformer fault', 4096: 'Isolation fault', 16384: 'Auxiliary power source failure',
                32767: 'Cooling system failure', 32833: 'Heatsink temperature measurement, Cooling liquid underpressure, Cooling system failure'
            }
    },
    'GE LV5 UL2': {
        'AlarmState1': 
            {
                0: 'Ok', 1: 'Heatsink overtemperature', 2: 'Earth fault', 4: 'Line undervoltage', 8: 'Water over temperature',
                16: 'Water pressure', 32: 'Water pressure sensor', 64: 'Water heating', 128: 'Fuse discharging resistor',
                256: 'Cubicle overheating', 512: 'PE cubicle over temperature', 1024: 'Line inductor over temperature', 
                4096: 'Pole grounding over current level 1', 8192: 'Pole grounding over current level 2',
                16384: 'Pole grounding feedback contactor', 32767: 'Pole grounding fuse'
            },
        'AlarmState2': 
            {
                0: 'Ok', 1: 'CPU board over temperature', 2: 'CPU core overtemperature', 4: 'DC Link unbalance', 8: 'Fan lifetime expired',
                16: 'DC link voltage oscilation', 32: 'Water temperature sensor', 64: 'External temperature sensor',
                96: 'Water temperature sensor, External temperature sensor',
                128: 'Line inductor temperature sensor',
                256: 'Power electronics cubicle temperature sensor', 512: 'Shutdown with Local/Remote change-over', 
                1024: 'Disconnection from PV generation', 2048: 'Inaccurate power measurement for MPPT',
                4096: 'External fan', 8192: 'Switching cycle AC circuit breaker', 
                16384: 'AC breaker life time exceeded', 32767: 'DC breaker life time exceeded'
            },
        'AlarmState3': 
            {
                0: 'Ok', 1: 'Isolation warning', 2: 'Controller floating warning', 4: 'Overvoltage protection', 32: 'Filter cubicle fan', 
                128: 'Cubicle temperature warning', 256: 'SRAM failure', 512: 'Fieldbus communication warning', 1024: 'MV transformer overtemperature',
                2048: 'Isolation', 4096: 'CPU synchronization',
            },
        'FaultState1':
            {
                0: 'Ok', 1: 'ECAT operation fault', 2: 'FPGA version wrong', 4: 'PIB watchdog failure',
                8: 'DC link overvoltage', 16: 'Controller floating fault', 32: 'DC link short circuit',
                64: 'Pole grounding',  256: 'IGBT desaturation fault', 1024: 'IGBT feedback fault',
                8192: 'PIB Central fault',
            },
        'FaultState2': 
            {
                0: 'Ok', 1: 'PIB internal power supply', 2: 'Short Firing', 8: 'Amplifier Connection',
                32: 'Current clipping timeout',  64: 'Short circuit', 256: 'Heatsink overtemperature', 512: '24V terminal short circuit',
                1024: 'Fieldbus connection fault', 2048: 'Battery synchronization fault', 4096: 'Short circuit to earth',  8192: 'DC link undervoltage'
            },
        'FaultState3': 
            {
                0: 'Ok', 1: 'Line voltage fault', 2: 'Line voltage measurent fault', 4: 'AC breaker activated', 8: 'AC breaker feedback',
                16: 'Precharge failure', 32: 'Line filter', 64: 'Discharge contactor feedback', 128: 'DC breaker feedback',
                256: 'Amplifier board contactor', 512: 'Current direction fault', 1024: 'UPS failure', 2048: 'PV circuit short circuit',
                4096: 'Isolation fault', 8192: 'Maximum output frequency', 16384: 'FPGA firing', 32767: 'PIB hardware config'
            },
        'FaultState4': 
            {
                0: 'Ok', 1: 'Heatsink temperature measurement', 2: 'Heatsink undertemperature', 4: 'External fan',
                8: 'Cubicle fan', 16: 'Auxiliary supply', 32: 'Water over temperature', 64: 'Water pressure',
                128: 'Water pump', 256: 'Initial water heating, initial cooling', 512: 'Cubicle over temperature',
                1024: 'Line inductor overtemperature', 2048: 'MV transformer vacuum', 4096: 'MV transformer pressure', 
                8192: 'MV transformer oil level', 16384: 'MV transformer temperature',
                32767: 'Cooling system failure'
            }
    },
    'INGETEAM abk1000': {
        'FaultState1': 
        {
            0: 'Ok', 0x0001: 'Fan blocked', 0x0002: 'Derating due to temperature', 0x0004: 'Undertemperature',
            0x0008: 'High voltage input', 0x0010: 'Communication error with strings kit', 0x0020: 'Self consumption comm. error',
            0x0040: 'Surge arrester error', 0x0080: 'LVRT failure', 0x0100: 'Isolation failure', 0x0200: 'DC undervoltage',
            0x0400: 'Power consumption on hold', 0x0800: 'Communication error', 0x1000: 'Fan failure', 0x2000: 'Limitation due to difference with other inverters connected thorough CAN', 
            0x4000: 'Grid synchronization failure', 0x8000:'High temperature difference between phases'
        },
        'AlarmState1': 
        {
            0: 'Ok', 0x0001: 'VDC1 overvoltage', 0x0002: 'VDC2 overvoltage', 0x0004: 'IDC1 overcurrent', 0x0008: 'IDC2 overcurrent',
            0x0010: 'VBUS overvoltage', 0x0020: 'Inst. VAC overvoltage', 0x0040: 'VAC out of range', 0x0080: 'Inst. IAC overvoltage',
            0x0100: 'IAC out of range', 0x0200: 'FAC out of range', 0x0400: 'DC/DC PWM',  0x0800: 'DC/AC PWM', 0x1000 : 'DC/DC Hardware',
            0x2000: 'DC/AC Hardware', 0x4000: 'Temperature alarm', 0x8000:'Persistent error'
        },
        'AlarmState2': 
        {
            0: 'Ok', 0x0001: 'Manual stop', 0x0002: 'Hardware error', 0x0004: 'Residual current', 0x0008: 'Firmware update',
            0x0010: 'PV power low', 0x0020: 'PV power too low to connect', 0x0040: 'Phase R overtemperature',
            0x0080: 'Phase S overtemperature', 0x0100: 'Phase T overtemperature', 0x0200: 'Converter overtemperature',
            0x0400: 'Ambient overtemperature', 0x0800: 'Coil overtemperature', 0x1000: 'Phase R Power electronics',
            0x2000: 'Phase S Power electronics', 0x4000: 'Phase T Power electronics', 0x8000:"Phase R overcurrent"
        },
        'AlarmState3': 
        {
            0: 'Ok', 0x0001: 'Phase S overcurrent', 0x0002: 'Phase T overcurrent', 0x0004: 'AC contactor failure',
            0x0008: 'DC contactor failure', 0x0010: 'AC protection', 0x0020: 'Surge arrestor Fault', 0x0040: 'Phase R fuse',
            0x0080: 'Phase S fuse', 0x0100: 'Phase T fuse', 0x0200: 'AC precharge failure', 0x0400: 'Detector board fan',
            0x0800: 'Coil cooling', 0x1000: 'Stack cooling', 0x2000: 'Electronics power source failure',
            0x4000: 'DC current injection', 0x8000:"Config. change"
        },
        'AlarmState4': 
        {
            0: 'Ok', 0x0001: 'Isolation fault', 0x0002: 'IAC saturation', 0x0004: 'VDC undervoltage', 0x0008: 'Manufacturer test',
            0x0010: 'Arc discharge detected', 0x0020: 'Saturation control', 0x0040: 'System synchronization failure', 0x0080: 'Phase R connection fiber failure',
            0x0100: 'Phase S connection fiber failure', 0x0200: 'Phase T connection fiber failure', 0x0400: 'Auxiliary power source failure', 0x0800: 'LVRT system failure',
            0x1000: 'Parallel communications failure', 0x2000: 'Earth board failure', 0x4000: 'Last alarm available'
        },
    },
    'INGETEAM Ch Ur':{
        'AlarmState1': {
            0x0000: 'Ok',
            0x0001: 'Grid frequency outside limits',
            0x0002: 'Grid voltage outside limits',
                
            0x0004: 'Current PI saturation',
            0x0008: 'inverter reset by Watch Dog',
            0x0010: 'Excessive RMS grid current',
            0x0020: 'Max. Temperature reached',
            0x0040: 'A/D converters reading error',
            0x0080: 'AC overcurrent',
            0x0100: 'AC protections',
            0x0200: 'DC protections',
            0x0400: 'DC Isolation Failure.',
            0x0800: 'Failure in power electronics',
            0x1000: 'Manual stop',
            0x2000: 'Change in configuration',
            0x4000: 'Excessive input voltage',
            0x8000: 'Minimim input voltage reached',
                
            0x0240: 'DC HW failure',
            0x1200: 'Blocked by Modbus command',
            0x7FFF: 'Fatal error ocurred',
                
            0x0003: 'Grid frequency outside limits, Grid voltage outside limits',
            0x0403: 'DC Isolation Failure, Grid frequency outside limits, Grid voltage outside limit.',
            0x1003: 'Manual stop, Grid frequency outside limits, Grid voltage outside limits',
            0x2243: 'Change in configuration,DC HW failure, Grid frequency outside limits, Grid voltage outside limits'
        }
    },
    'SUNGROW SG3125':{
        'FaultState1_M1': {
            0b0000000000000000000000000000000000: 'Ok',
            0b0000000000000000000000000000000001: 'DC undervoltage (M1)',
            0b0000000000000000000000000000000010: 'DC overvoltage (M1)',
            0b0000000000000000000000000000000100: 'AC undervoltage (M1)',
            0b0000000000000000000000000000001000: 'AC overvoltage (M1)',
            0b0000000000000000000000000000010000: 'Underfrequency (M1)',
            0b0000000000000000000000000000100000: 'Overfrequency (M1)',
            0b0000000000000000000000000001000000: 'Contactor fault (M1)',
            0b0000000000000000000000000010000000: 'Islanding protection (M1)',
            0b0000000000000000000000000100000000: 'Sensor failure (M1)',
            0b0000000000000000000000001000000000: 'PDP protection (M1)',
            0b0000000000000000000000010000000000: 'Module overtemperature (M1)',
            0b0000000000000000000000100000000000: 'Reactor overtemperature (M1)',
            0b0000000000000000000001000000000000: 'Transformer overtemperature (M1)',
            0b0000000000000000000010000000000000: 'DC leakage current protection (M1)',
            0b0000000000000000000100000000000000: 'AC leakage current protection (M1)',
            0b0000000000000000001000000000000000: 'Overload protection (M1)',
            0b0000000000000000010000000000000000: 'Reserved (M1)',
            0b0000000000000000100000000000000000: 'GFDI protection (M1)',
            0b0000000000000001000000000000000000: 'Fan fault (M1)',
            0b0000000000000010000000000000000000: 'DC fuse fault (M1)',
            0b0000000000000100000000000000000000: 'Detection fuse fault (M1)',
            0b0000000000001000000000000000000000: 'DC overcurrent (M1)',
            0b0000000000010000000000000000000000: 'AC overcurrent (M1)',
            0b0000000000100000000000000000000000: 'Frequency abnormal (M1)',
            0b0000000001000000000000000000000000: 'Temperature abnormal (M1)',
            0b0000000010000000000000000000000000: 'Hardware fault (M1)',
            0b0000000100000000000000000000000000: 'Grounding fault (M1)',
            0b0000001000000000000000000000000000: 'Bus overvoltage (M1)',
            0b0000010000000000000000000000000000: 'Bus undervoltage (M1)',
            0b0000100000000000000000000000000000: 'Inverter overvoltage (M1)',
            0b0001000000000000000000000000000000: 'Reserved (M1)',
            0b0010000000000000000000000000000000: 'Reserved (M1)',
        },
        'FaultState2_M1': {
            0b000000000000000000000000000000000: 'Ok',
            0b000000000000000000000000000000001: 'Insulation impedance (M1)',
            0b000000000000000000000000000000010: '(AC) SPD fault (M1)',
            0b000000000000000000000000000000100: 'Sampling fault (M1)',
            0b000000000000000000000000000001000: '(PV) polarity reversed (M1)',
            0b000000000000000000000000000010000: 'Control power supply abnormal (M1)',
            0b000000000000000000000000000100000: 'Backup power supply abnormal (M1)',
            0b000000000000000000000000001000000: '(AC) current unbalance (M1)',
            0b000000000000000000000000010000000: 'AC fuse fault (M1)',
            0b000000000000000000000000100000000: '(DC) SPD fault (M1)',
            0b000000000000000000000001000000000: 'Buffer contactor fault (M1)',
            0b000000000000000000000010000000000: 'DC injection fault (M1)',
            0b000000000000000000000100000000000: 'DC switch fault (M1)',
            0b000000000000000000001000000000000: 'Device code repeat fault (M1)',
            0b000000000000000000010000000000000: 'Parallel operation communication fault (M1)',
            0b000000000000000000100000000000000: 'Control cabinet temperature abnormal (M1)',
            0b000000000000000001000000000000000: 'DC fuse grounding fault (M1)',
            0b000000000000000010000000000000000: 'Reversed branch over-current (M1)',
            0b000000000000000100000000000000000: 'Grid voltage unbalance (M1)',
            0b000000000000001000000000000000000: 'Reserved (M1)',
            0b000000000000010000000000000000000: 'Reserved (M1)',
            0b000000000000100000000000000000000: 'AC cabinet over-temperature (M1)',
            0b000000000001000000000000000000000: 'AC switch disconnection (M1)',
            0b000000000010000000000000000000000: 'AC switch fault (M1)',
            0b000000000100000000000000000000000: 'Soft start fault (M1)',
            0b000000001000000000000000000000000: 'DC voltage sampling fault (M1)',
            0b000000010000000000000000000000000: 'Fan 2 fault (M1)',
            0b000000100000000000000000000000000: 'Current unbalance 2 (M1)',
            0b000001000000000000000000000000000: 'Current unbalance 3 (M1)',
            0b000010000000000000000000000000000: 'Drive board fault (M1)',
            0b000100000000000000000000000000000: 'DC cabinet over-temperature (M1)',
            0b001000000000000000000000000000000: 'Neutral point potential shift (M1)',
            0b010000000000000000000000000000000: 'Carrier sync fault (M1)',
        },
        'FaultState1_M2': {
            0b0000000000000000000000000000000000: 'Ok',
            0b0000000000000000000000000000000001: 'DC undervoltage (M2)',
            0b0000000000000000000000000000000010: 'DC overvoltage (M2)',
            0b0000000000000000000000000000000100: 'AC undervoltage (M2)',
            0b0000000000000000000000000000001000: 'AC overvoltage (M2)',
            0b0000000000000000000000000000010000: 'Underfrequency (M2)',
            0b0000000000000000000000000000100000: 'Overfrequency (M2)',
            0b0000000000000000000000000001000000: 'Contactor fault (M2)',
            0b0000000000000000000000000010000000: 'Islanding protection (M2)',
            0b0000000000000000000000000100000000: 'Sensor failure (M2)',
            0b0000000000000000000000001000000000: 'PDP protection (M2)',
            0b0000000000000000000000010000000000: 'Module overtemperature (M2)',
            0b0000000000000000000000100000000000: 'Reactor overtemperature (M2)',
            0b0000000000000000000001000000000000: 'Transformer overtemperature (M2)',
            0b0000000000000000000010000000000000: 'DC leakage current protection (M2)',
            0b0000000000000000000100000000000000: 'AC leakage current protection (M2)',
            0b0000000000000000001000000000000000: 'Overload protection (M2)',
            0b0000000000000000010000000000000000: 'Reserved (M2)',
            0b0000000000000000100000000000000000: 'GFDI protection (M2)',
            0b0000000000000001000000000000000000: 'Fan fault (M2)',
            0b0000000000000010000000000000000000: 'DC fuse fault (M2)',
            0b0000000000000100000000000000000000: 'Detection fuse fault (M2)',
            0b0000000000001000000000000000000000: 'DC overcurrent (M2)',
            0b0000000000010000000000000000000000: 'AC overcurrent (M2)',
            0b0000000000100000000000000000000000: 'Frequency abnormal (M2)',
            0b0000000001000000000000000000000000: 'Temperature abnormal (M2)',
            0b0000000010000000000000000000000000: 'Hardware fault (M2)',
            0b0000000100000000000000000000000000: 'Grounding fault (M2)',
            0b0000001000000000000000000000000000: 'Bus overvoltage (M2)',
            0b0000010000000000000000000000000000: 'Bus undervoltage (M2)',
            0b0000100000000000000000000000000000: 'Inverter overvoltage (M2)',
            0b0001000000000000000000000000000000: 'Reserved (M2)',
            0b0010000000000000000000000000000000: 'Reserved (M2)',
        },
        'FaultState2_M2': {
            0b000000000000000000000000000000000: 'Ok',
            0b000000000000000000000000000000001: 'Insulation impedance (M2)',
            0b000000000000000000000000000000010: '(AC) SPD fault (M2)',
            0b000000000000000000000000000000100: 'Sampling fault (M2)',
            0b000000000000000000000000000001000: '(PV) polarity reversed (M2)',
            0b000000000000000000000000000010000: 'Control power supply abnormal (M2)',
            0b000000000000000000000000000100000: 'Backup power supply abnormal (M2)',
            0b000000000000000000000000001000000: '(AC) current unbalance (M2)',
            0b000000000000000000000000010000000: 'AC fuse fault (M2)',
            0b000000000000000000000000100000000: '(DC) SPD fault (M2)',
            0b000000000000000000000001000000000: 'Buffer contactor fault (M2)',
            0b000000000000000000000010000000000: 'DC injection fault (M2)',
            0b000000000000000000000100000000000: 'DC switch fault (M2)',
            0b000000000000000000001000000000000: 'Device code repeat fault (M2)',
            0b000000000000000000010000000000000: 'Parallel operation communication fault (M2)',
            0b000000000000000000100000000000000: 'Control cabinet temperature abnormal (M2)',
            0b000000000000000001000000000000000: 'DC fuse grounding fault (M2)',
            0b000000000000000010000000000000000: 'Reversed branch over-current (M2)',
            0b000000000000000100000000000000000: 'Grid voltage unbalance (M2)',
            0b000000000000001000000000000000000: 'Reserved (M2)',
            0b000000000000010000000000000000000: 'Reserved (M2)',
            0b000000000000100000000000000000000: 'AC cabinet over-temperature (M2)',
            0b000000000001000000000000000000000: 'AC switch disconnection (M2)',
            0b000000000010000000000000000000000: 'AC switch fault (M2)',
            0b000000000100000000000000000000000: 'Soft start fault (M2)',
            0b000000001000000000000000000000000: 'DC voltage sampling fault (M2)',
            0b000000010000000000000000000000000: 'Fan 2 fault (M2)',
            0b000000100000000000000000000000000: 'Current unbalance 2 (M2)',
            0b000001000000000000000000000000000: 'Current unbalance 3 (M2)',
            0b000010000000000000000000000000000: 'Drive board fault (M2)',
            0b000100000000000000000000000000000: 'DC cabinet over-temperature (M2)',
            0b001000000000000000000000000000000: 'Neutral point potential shift (M2)',
            0b010000000000000000000000000000000: 'Carrier sync fault (M2)',
        },
        'AlarmState_M1': {
            0b000000000000000000000000000000000: 'Ok',
            0b000000000000000000000000000000001: 'Temperature abnormal alarm (M1)',
            0b000000000000000000000000000000010: 'Low insulation resistance (M1)',
            0b000000000000000000000000000000100: 'GFRT operation (M1)',
            0b000000000000000000000000000001000: 'CT unbalance (M1)',
            0b000000000000000000000000000010000: 'DC fuse abnormal (M1)',
            0b000000000000000000000000000100000: 'DSP-MDC communication abnormal (M1)',
            0b000000000000000000000000001000000: 'DC sensor abnormal (M1)',
            0b000000000000000000000000010000000: 'DC SPD alarm (M1)',
            0b000000000000000000000000100000000: 'AC SPD alarm (M1)',
            0b000000000000000000000001000000000: 'Bypass circuit breaker abnormal (M1)',
            0b000000000000000000000010000000000: 'Bypass fuse abnormal (M1)',
            0b000000000000000000000100000000000: 'Ground fuse abnormal (M1)',
            0b000000000000000000001000000000000: 'DC switch abnormal (M1)',
            0b000000000000000000010000000000000: 'Anti-PID power supply abnormal (M1)',
            0b000000000000000000100000000000000: 'Fan abnorma (M1)l',
            0b000000000000000001000000000000000: 'External power supply abnormal (M1)',
            0b000000000000000010000000000000000: 'DC bypass forward overcurrent alarm (M1)',
            0b000000000000000100000000000000000: 'DC bypass reserve overcurrent alarm (M1)',
            0b000000000000001000000000000000000: 'Tributary board communication error (M1)',
            0b000000000000010000000000000000000: 'AC circuit breaker abnormal (M1)',
            0b000000000000100000000000000000000: 'Energy meter communication abnormal (M1)',
            0b000000000001000000000000000000000: 'Fan 2 anomaly (M1)',
            0b000000000010000000000000000000000: 'Contactor contact abnorma (M1)l',
            0b000000000100000000000000000000000: 'Temperature & humidity board communication error (M1)',
            0b000000001000000000000000000000000: 'Frequency deviation active power regulation (M1)',
            0b000000010000000000000000000000000: 'Voltage deviation reactive power regulation (M1)',
            268435456: 'Abnormal derating heatsink (M1)'
        },
        'AlarmState_M2': {
            0b000000000000000000000000000000000: 'Ok',
            0b000000000000000000000000000000001: 'Temperature abnormal alarm (M2)',
            0b000000000000000000000000000000010: 'Low insulation resistance (M2)',
            0b000000000000000000000000000000100: 'GFRT operation (M2)',
            0b000000000000000000000000000001000: 'CT unbalance (M2)',
            0b000000000000000000000000000010000: 'DC fuse abnormal (M2)',
            0b000000000000000000000000000100000: 'DSP-MDC communication abnormal (M2)',
            0b000000000000000000000000001000000: 'DC sensor abnormal (M2)',
            0b000000000000000000000000010000000: 'DC SPD alarm (M2)',
            0b000000000000000000000000100000000: 'AC SPD alarm (M2)',
            0b000000000000000000000001000000000: 'Bypass circuit breaker abnormal (M2)',
            0b000000000000000000000010000000000: 'Bypass fuse abnormal (M2)',
            0b000000000000000000000100000000000: 'Ground fuse abnormal (M2)',
            0b000000000000000000001000000000000: 'DC switch abnormal (M2)',
            0b000000000000000000010000000000000: 'Anti-PID power supply abnormal (M2)',
            0b000000000000000000100000000000000: 'Fan abnorma (M2)l',
            0b000000000000000001000000000000000: 'External power supply abnormal (M2)',
            0b000000000000000010000000000000000: 'DC bypass forward overcurrent alarm (M2)',
            0b000000000000000100000000000000000: 'DC bypass reserve overcurrent alarm (M2)',
            0b000000000000001000000000000000000: 'Tributary board communication error (M2)',
            0b000000000000010000000000000000000: 'AC circuit breaker abnormal (M2)',
            0b000000000000100000000000000000000: 'Energy meter communication abnormal (M2)',
            0b000000000001000000000000000000000: 'Fan 2 anomaly (M2)',
            0b000000000010000000000000000000000: 'Contactor contact abnormal (M2)',
            0b000000000100000000000000000000000: 'Temperature & humidity board communication error (M2)',
            0b000000001000000000000000000000000: 'Frequency deviation active power regulation (M2)',
            0b000000010000000000000000000000000: 'Voltage deviation reactive power regulation (M2)',
            268435456: 'Abnormal derating heatsink (M2)'
        },
    },
    'PE HEMSW01SE':{}
}
const mapMax = {
    "Juazeiro":{
        "Inverter":{
            P: 1050,
            Q: 1050,
            P_set: 105,
            Q_set: 110,
            Q1_temp: 80,
            Q2_temp: 80,
            Q3_temp: 80,
            Q4_temp: 80,
        }
    },
    "Sao Pedro":{
        "Inverter":{
            P: 1050,
            Q: 1050,
            P_set: 105,
            Q_set: 110,
            Q1_temp: 80,
            Q2_temp: 80,
            Q3_temp: 80,
            Q4_temp: 80,
        }
    },
    "Jacaranda":{
        "Inverter":{
            P: 1700,
            Q: 1700,
            P_set: 105,
            Q_set: 110,
            HSA_temp: 80,
            HSB_temp: 80,
            HSC_temp: 80,
            P_set_reason: 2,
            max_IGBT_temp: 120,
        }
    },
    "Barreiras":{
        "Inverter":{
            P: 1700,
            Q: 1700,
            P_set: 105,
            Q_set: 110,
            HSA_temp: 80,
            HSB_temp: 80,
            HSC_temp: 80,
            P_set_reason: 2,
            max_IGBT_temp: 120,
        }
    },
    "Sol do Futuro":{
        "Inverter":{
            P: 1280,
            Q: 1280,
            P_set: 105,
            Q_set: 110,
            HSA_temp: 80,
            HSB_temp: 80,
            HSC_temp: 80,
            P_set_reason: 2,
        }
    },
    "CasaBlanca 1":{
        "Inverter":{
            P: 1700,
            Q: 1700,
            P_set: 105,
            Q_set: 110,
            HSA_temp: 80,
            HSB_temp: 80,
            HSC_temp: 80,
            P_set_reason: 2,
        }
    },
    "CasaBlanca 2":{
        "Inverter":{
            P: 3600,
            Q: 3600,
            P_set: 105,
            Q_set: 110,
            HSA_temp: 80,
            HSB_temp: 80,
            HSC_temp: 80,
            P_set_reason: 2,
        }
    },
    "Boa Sorte":{
        "Inverter":{
            P: 3600,
            Q: 3600,
            P_set: 105,
            Q_set: 110,
            HSA_temp: 80,
            HSB_temp: 80,
            HSC_temp: 80,
            P_set_reason: 2,
        }
    },
    "Vista Alegre":{
        "Inverter":{
            P: 1210,
            Q: 1210,
            P_set: 110,
            Q_set: 110,
            HSA_temp: 80,
            HSB_temp: 80,
            HSC_temp: 80,
            P_set_reason: 2,
        }
    },
    "La Pimienta":{
        "Inverter":{
            P: 3400,
            Q: 3400,
            P_set: 105,
            Q_set: 110,
            HSA_temp: 80,
            HSB_temp: 80,
            HSC_temp: 80,
            P_set_reason: 2,
        }
        
    },
    "Guajiro":{
        "Inverter":{
            P: 3682,
            Q: 3682,
            P_set: 105,
            Q_set: 110,
            HSA_temp: 80,
            HSB_temp: 80,
            HSC_temp: 80,
            P_set_reason: 2,
        }
        
    },
    "Sol del Desierto":{
        "Inverter":{
            P: 3400,
            Q: 3400,
            P_set: 105,
            Q_set: 110,
            HSA_temp: 80,
            HSB_temp: 80,
            HSC_temp: 80,
            P_set_reason: 2,
        }
        
    },
    "Javiera":{
        "Inverter":{
            P: 300,
            Q: 300,
            P_set: 105,
            Q_set: 110,
            HSA_temp: 80,
            HSB_temp: 80,
            HSC_temp: 80,
            P_set_reason: 2,
        }
        
    },
    "Quilapilun":{
        "Inverter":{
            P: 300,
            Q: 300,
            P_set: 105,
            Q_set: 110,
            HSA_temp: 80,
            HSB_temp: 80,
            HSC_temp: 80,
            P_set_reason: 2,
        }
        
    },
    "Litoral":{
        "Inverter":{
            P: 230,
            Q: 230,
            P_set: 105,
            Q_set: 110,
            HSA_temp: 80,
            HSB_temp: 80,
            HSC_temp: 80,
            P_set_reason: 2,
        }
        
    },
    "Naranjal":{
        "Inverter":{
            P: 280,
            Q: 280,
            P_set: 105,
            Q_set: 110,
            HSA_temp: 80,
            HSB_temp: 80,
            HSC_temp: 80,
            P_set_reason: 2,
        }
        
    }

}

const HVMap = {
    'Naranjal':{
        svg: NaranjalHV,
        elements: [
            {type:'rele', name:'C2'},
            {type:'rele', name:'C3'}, 
            {type:'rele', name:'C4'},
            {type:'rele', name:'C5'},
            {type:'rele', name:'C6'},
            {type:'rele', name:'C7'}
        ]
    },
    'Quilapilun':{
        svg: QuilapilunHV
    }
}

const plantInvertersType = {
    'Juazeiro': 'GE LV5 UL1',
    'Sao Pedro': 'GE LV5 UL1',
    'Jacaranda': 'INGETEAM abk1000',
    'Barreiras': 'INGETEAM abk1000',
    'Sol do Futuro': 'GE LV5 UL2',
    'CasaBlanca 1': 'INGETEAM abk1000',
    'CasaBlanca 2': 'SUNGROW SG3125',
    'La Pimienta': 'SUNGROW SG3125',
    'Sol del Desierto': 'SUNGROW SG3125',
    'Boa Sorte': 'SUNGROW SG3125',
    'Javiera': 'INGETEAM Ch Ur',
    'Quilapilun': 'INGETEAM Ch Ur',
    'Litoral': 'INGETEAM Ch Ur',
    'Naranjal': 'INGETEAM Ch Ur',
    'Guajiro': 'PE HEMSW01SE',
    'Vista Alegre': 'PE HEMSW01SE',
}

const initialState = {
        plantsNames,
        elementsTypes,
        mappingElementTypeVariables,
        plantInvertersType,
        faultMap,
        mapMax,
        isbsExclusionList,
        maxISB,
        HVMap
}


export const configSlice = createSlice({
    name: 'config',
    initialState,
    reducers: {}
})

// export const {} = configSlice.actions
export const ConfigReducer = configSlice.reducer