// import { Connector, subscribe } from 'react-mqtt-client'
import pako from 'pako'
import { useDispatch, useSelector } from "react-redux"
import mqtt from 'mqtt'
import { useEffect, useState } from 'react'
import { getTopicName } from '../utils'


// const BROKER_URL = 'mqtts://61bca8acdf104826ac2899dc883aed63.s2.eu.hivemq.cloud/mqtt'
// const PORT = 8884

// const BROKER_URL = 'ws://broker.hivemq.com/mqtt'
// const BROKER_URL = 'ws://20.55.80.49'
const BROKER_URL = 'ws://20.127.188.14'
const MQTT_OPTIONS = {
  // Clean session
  clean: true,
  connectTimeout: 4000,
  port: 60912,
  username:"helios_reader", 
  password:"A2DM9Wd7p52naWXtqFRV368yh6W3e8bt"

};

function MQTTConnection(){  
  const [client, setClient] = useState(null);
  const [, setConnectStatus] = useState(null);
  const dispatch = useDispatch()


  const {active_context} = useSelector(state => ({active_context: state.context.active}))
  const {plantName, elementType} = active_context
  const [topic, setTopic] = useState(undefined);

  // console.log(connectStatus)

  const mqttConnect = () => {
    setConnectStatus('Connecting');
    
    setClient(mqtt.connect(BROKER_URL, MQTT_OPTIONS));
  };
  const mqttSub = (subscription) => {
    if (client) {
        const old_topic = topic
        const { qos } = subscription;
        const new_topic = subscription.topic
        setTopic(new_topic)

        client.subscribe(new_topic, (error) => {
          // console.log('Sub', new_topic)
          if (error) {
            console.log('Subscribe to topics error', error)
            return
          }
          if (new_topic !==old_topic){
            mqttUnSub({topic: old_topic, qos:2})
          }
          
        });
      }
    };
  
  const mqttUnSub = (subscription) => {
      if (client) {
        const old_topic = subscription.topic;
        console.log('Unsub',old_topic)
        if (old_topic !==undefined) {
          client.unsubscribe(old_topic, error => {
            if (error) {
              console.log('Unsubscribe error', error)
              return
            }
          });
        }
      }
    };

  const new_topic = getTopicName(plantName, elementType)
  if (new_topic !== topic) {
    mqttSub({topic: new_topic, qos:0})
  }  
  

  useEffect(()=>{
      if (client !== null) {
        client.on('connect', () => {
          setConnectStatus('Connected');
        });
        client.on('error', (err) => {
          console.error('Connection error: ', err);
          client.end();
        });
        client.on('reconnect', () => {
          setConnectStatus('Reconnecting');
        });
        client.on('message', (mtopic, message) => {
          
          const payload = { mtopic, message: JSON.parse(pako.inflate(message, { to: 'string' })) };
          dispatch({
                  type: 'mqtt/ADD_SAMPLE',
                  payload: payload.message,
                  context: {topic:mtopic}
              })
        });
      } else { 
        mqttConnect(BROKER_URL)
      }
    }, [client, dispatch]);
  
  return <></>
}


function MQTTAllConnection(){  
  const [client, setClient] = useState(null);
  const [, setConnectStatus] = useState(null);
  const dispatch = useDispatch()


  const {options} = useSelector(state => state)
  const {plantsNames} = options
  const [topics, setTopics] = useState([]);

  // console.log(connectStatus)
  const mqttConnect = (host) => {
    setConnectStatus('Connecting');
    
    setClient(
      mqtt.connect( BROKER_URL, MQTT_OPTIONS)
    );
  };  
  
  const mqttSub = (subscription) => {
    if (client) {
        const { qos } = subscription;
        const new_topic = subscription.topic
        if (!topics.includes(new_topic)){
          client.subscribe(new_topic, { qos }, (error) => {
            console.log('Sub', new_topic)
            if (error) {
              console.log('Subscribe to topics error', error)
              return
            }
            let t = topics
            t.push(new_topic)
            setTopics(t);
          })
          
        }

        
      }
    };
  
  // const mqttUnSub = (subscription) => {
  //     if (client) {
  //       const old_topic = subscription.topic;
  //       console.log('Unsub',old_topic)
  //       if (old_topic !==undefined) {
  //         client.unsubscribe(old_topic, error => {
  //           if (error) {
  //             console.log('Unsubscribe error', error)
  //             return
  //           }
  //         });
  //       }
  //     }
  //   };

  useEffect(()=>{
    plantsNames.forEach(plantName => {
      let new_topic = getTopicName(plantName, 'Inverter')
      mqttSub({topic:new_topic, qos:2})
      new_topic = getTopicName(plantName, 'WS')
      mqttSub({topic:new_topic, qos:2})
    })
    
  })
  ;
  


  useEffect(()=>{
      if (client !== null) {
        client.on('connect', () => {
          setConnectStatus('Connected');
        });
        client.on('error', (err) => {
          console.error('Connection error: ', err);
          client.end();
        });
        client.on('reconnect', () => {
          setConnectStatus('Reconnecting');
        });
        client.on('message', (mtopic, message) => {
          // console.log(mtopic)
          const payload = { mtopic, message: JSON.parse(pako.inflate(message, { to: 'string' })) };
          dispatch({
                  type: 'mqtt/ADD_SAMPLE',
                  payload: payload.message,
                  context: {topic:mtopic}
              })
        });
      } else { 
        mqttConnect(BROKER_URL)
      }
    }, [client, dispatch]);
  
  return <></>
}


export default function MQTTComponent(props){
  console.log(props)
  if (props.val === "all"){
    return <MQTTAllConnection></MQTTAllConnection>
  }

  return <MQTTConnection></MQTTConnection>
}