export const color = {
    blue1: "#0cb5ff",
    blue2: "#0071e9",

    green1: '#6ed42b',
    green2: '#18a223',

    yellow2: '#f3c300',
    orange1: '#ffa400',
    orange2: '#ff671d',

    red1: '#ff0000',
    grey1: '#dbdbdb',
    grey2: '#4f4f4f',
}

// --yellow-1: #fdd800;
// --yellow-2: #f3c300;
// --orange-1: #ffa400;
// --orange-2: #ff671d;
// --dark-grey: #4f4f4f;
// --light-grey: #dbdbdb;
// --light-grey-half: #ededed;
// --blue-1: #0cb5ff;
// --blue-2: #0071e9;
// --green-1: #6ed42b;
// --green-2: #18a223;
// --gradient-orange: linear-gradient(5deg,var(--yellow-2), var(--orange-2));
// --gradient-blue: linear-gradient(5deg,var(--blue-1), var(--blue-2));
// --gradient-green: linear-gradient(5deg,var(--green-1), var(--green-2));