import { color } from "../../utils/colors"
import {ReactComponent as NoCommsIcon} from '../../assets/eye-cancelled-filled.svg'
import {ReactComponent as AlertIcon} from '../../assets/cancel.svg'
import "./horizontal.css"

export function HorizontalBar(props){
    const {value, maxValue, minValue, title, notificate, onClick, comms, sat} = props
    const ratio = (value - minValue)/(maxValue - minValue)



    const config = {
        width: 100,
        height: 8,
        ratio,
        title,
        onClick, sat
    }

    

    let bar
    if (!sat){
        bar = <BlueBar config = {config}></BlueBar>
    }else{
        bar = <OrangeBar config = {config}></OrangeBar>
    }


    // console.log(title, notificate)
    let dot = <NoAlarmsDot></NoAlarmsDot>
    if (!comms){
        dot = <NoCommsIcon style={{marginLeft:3}} fill={color.yellow2} width={10} height={10}></NoCommsIcon>
    }

    if (notificate) {
        dot = <AlertIcon style={{marginLeft:3}} fill={color.red1} width={10} height={10}></AlertIcon>
    }
    

    return <span key={title} title={title} className="horizontal-bar" onClick={onClick}>
        {bar}
        {dot}
    </span>
}



function BlueBar(props){
    const {config} = props
    const {ratio} = config

    return <svg className="chart" width={config.width} height={config.height*1.1} transform="translate(0,0)">
        <defs>
            <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
                <stop offset="0%" style={{"stop-color": color.blue1, "stop-opacity":1}} />
                <stop offset="100%" style={{"stop-color": color.blue2, "stop-opacity":1}} />
            </linearGradient>
        </defs>

        <g>
            <rect width={config.width} height={config.height} fill="#dbdbdb"></rect>
        </g>
        <g>
            <rect width={config.width*Math.max(ratio, 0)} height={config.height} fill={"url(#grad1)"}></rect>
        </g>            
    </svg>
}

function OrangeBar(props){
    const {config} = props
    const {ratio} = config

    return <svg className="chart" width={config.width} height={config.height*1.1} transform="translate(0,0)">
        <defs>
            <linearGradient id="grad2" x1="0%" y1="0%" x2="100%" y2="0%">
                <stop offset="0%" style={{"stop-color": color.yellow2, "stop-opacity":1}} />
                <stop offset="100%" style={{"stop-color": color.orange2, "stop-opacity":1}} />
            </linearGradient>
        </defs>

        <g>
            <rect width={config.width} height={config.height} fill="#dbdbdb"></rect>
        </g>
        <g>
            <rect width={config.width*Math.max(ratio, 0)} height={config.height} fill={"url(#grad2)"}></rect>
        </g>            
    </svg>
}


function NoAlarmsDot(){
    return <svg style={{marginLeft:3}} width={10} height={10}>
        <g>
            <circle r="3.2" stroke="green" strokeWidth="0" fill={color.grey1}  transform={"translate(5,5)"} />
        </g>
    </svg>
}